<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4776 7.01106C16.485 7.01102 16.4925 7.01101 16.5 7.01101C18.9853 7.01101 21 9.02942 21 11.5193C21 13.8398 19.25 15.7508 17 16M16.4776 7.01106C16.4924 6.84606 16.5 6.67896 16.5 6.51009C16.5 3.46695 14.0376 1 11 1C8.12324 1 5.76233 3.21267 5.52042 6.03192M16.4776 7.01106C16.3753 8.14759 15.9286 9.1846 15.2428 10.0165M5.52042 6.03192C2.98398 6.27373 1 8.41392 1 11.0183C1 13.4417 2.71776 15.4632 5 15.9273M5.52042 6.03192C5.67826 6.01687 5.83823 6.00917 6 6.00917C7.12582 6.00917 8.16474 6.38194 9.00049 7.01101"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 19L11 11M11 19C10.2998 19 8.99153 17.0057 8.5 16.5M11 19C11.7002 19 13.0085 17.0057 13.5 16.5"
      :stroke="iconStroke"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '20',
    },
    width: {
      type: String,
      default: '22',
    },
    color: {
      type: String,
      default: 'primary-purple-600',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStroke() {
      return `var(--${this.color})`
    },
  },
}
</script>
